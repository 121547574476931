<template>
    <Dialog ref="dialog" :config="config" :visible='visible' :cancelDialog="cancelDialog" :confirmDialog='confirmDialog' :isdeling='isdeling'>
      <div v-loading='isLoading'>
        <p class="text">
          <span>模板名称 <el-input v-model="settingData.name" placeholder="请输入..." class="w180" disabled/></span>
          <span>凭证字
            <el-select v-model="settingData.groupId" placeholder="请选择" class="w180">
              <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </span>
        </p>
        <table class="setting" cellpadding="0" cellspacing="0">
          <thead>
            <tr>
              <th class="setting_subject">会计科目</th>
              <th class="setting_direction">方向</th>
              <th class="setting_value">取值</th>
            </tr>
          </thead>
          <tbody>
            <tr class="setting_item" v-for="(data,index) in settingData.salaryTemplateEntryDtoList" :key="data.id">
              <td class="setting_subject">
                <el-select v-model="data.subId" placeholder="请选择" filterable class="settingSelect">
                  <el-option v-for="item in subjectList" :key="item.id" :label="item.code +' '+item.fullName" :value="item.id">
                  </el-option>
                </el-select>
              </td>
              <td class="setting_direction">
                <el-select v-model="data.direction" placeholder="请选择" filterable class="settingSelect">
                  <el-option v-for="item in directionList" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </td>
              <td class="setting_value">
                <el-select v-model="data.code" placeholder="请选择" filterable class="settingSelect">
                  <el-option v-for="item in valueList" :key="item.code" :label="item.name" :value="item.code">
                  </el-option>
                </el-select>
              </td>
              <i class="iconfont iconicon1-21" @click="add(index,data)"></i>
              <i class="iconfont iconicon1-18" @click="del(index,data)"></i>
            </tr>
          </tbody>
        </table>
      </div>
    </Dialog>
</template>

<script>
import Dialog from "@/components/Dialog";
const uuid = require('uuid');
export default {
  props: {
    settingData:{
      type: Object,
    },
    visible: {
      type: Boolean,
    },
    isdeling: {
      type: Boolean,
    },
    cancelDialog:{
      type: Function,
      default: () => {},
    },
    confirmDialog:{
      type: Function,
      default: () => {},
    }
  },
  components: {
    Dialog,
  },
  data() {
    return {
      isLoading: false,
      typeOptions:[],
      subjectList:[],
      directionList : [{label:'借',value:'1',},{label:'贷',value:'2',}],
      valueList:[
            {label: '个人 养老保险',value:'1'},
            {label: '个人 医疗保险',value:'2'},
            {label: '个人 失业保险',value:'3'},
            {label: '个人 工伤保险',value:'4'},
            {label: '个人 生育保险',value:'5'},
            {label: '个人 住房公积金',value:'6'},
            {label: '公司 养老保险',value:'7'},
            {label: '公司 医疗保险',value:'8'},
            {label: '公司 失业保险',value:'9'},
            {label: '公司 工伤保险',value:'10'},
            {label: '公司 生育保险',value:'11'},
            {label: '公司 住房公积金',value:'12'},
            {label: '工资',value:'13'},
            {label: '津贴补助',value:'14'},
            {label: '奖金',value:'15'},
            {label: '其他所得',value:'16'},
            {label: '考勤扣款',value:'17'},
            {label: '其他扣款',value:'18'},
            {label: '工资总额',value:'19'},
            {label: '应扣工资',value:'20'},
            {label: '代扣个人款项',value:'21'},
            {label: '代扣个人所得税',value:'22'},
            {label: '实发工资调整',value:'23'},
            {label: '实发工资',value:'24'},
            {label: '公司承担款项',value:'25'},
            {label: '员工成本合计',value:'26'},
            {label: '应发工资',value:'27'},
      ],
      config: {
        top: '20vh',
        width: '35rem',
        title: '生成凭证规则',
        center: true,
        btnTxt: ['确定','取消'],
      },
    };
  },
  methods: {
      add(index) {
        this.settingData.salaryTemplateEntryDtoList.splice(index + 1, 0, {
          id: uuid.v1(),
          subId: '',
          subFullName: "",
          subCode: "",
          direction: "1",
          code: ""
        })
      },

      del(key, item) {
        if (this.settingData.salaryTemplateEntryDtoList.length > 2) {//数据不少于两行
          this.settingData.salaryTemplateEntryDtoList.splice(key, 1);
        } else {
          this.settingData.salaryTemplateEntryDtoList.splice(key, 1);  //先删除 后尾部添加
          this.settingData.salaryTemplateEntryDtoList.push({
            id: uuid(),
            subId: '',
            subFullName: "",
            subCode: "",
            direction: "1",
            code: ""
          })
        }
      },

      getRestaurants() {  //获取所有科目列表
        this.$store.dispatch("GetAllLeafSubject", {
          asId: this.getToken('asId'),
        }).then(res => {
          this.isLoading = true;
          if (res.success) {
            this.isLoading = false;
            this.subjectList = res.data;
          } else {
            einvAlert.error("提示",res.msg)
          }
        }).catch(err => {
          this.isLoading = false
        })
      },

      getRuleList() {  //查询凭证规则取值列表
        this.$store.dispatch("GetsalaryTemplateListRule", {
          asId: this.getToken('asId'),
        }).then(res => {
          this.isLoading = true;
          if (res.success) {
            this.isLoading = false;
            this.valueList = res.data;
          } else {
            einvAlert.error("提示",res.msg)
          }
        }).catch(err => {
          this.isLoading = false
        })
      },

      getGroupList() { //获取所有凭证字列表
        this.isLoading = true;
        this.$store.dispatch("FindGroupList", {
          asId: this.getToken('asId'),
          page: 1,
          rows: 100000,  //凭证字分页
        }).then(res => {
          if (res.success) {
            this.typeOptions = res.data.map((item) => {  //梳理数组结构
              return {
                value: item.id,
                label: item.name,
                title: item.title,
              };
            });
          } else {
            einvAlert.error("提示",res.msg)
          }
          this.isLoading = false
        }).catch(err => {
          this.isLoading = false
        })
      },
  },
  watch: {
    visible(val) {
      if(val){
        this.getRuleList();
        this.getGroupList();
        this.getRestaurants();
      }
    },
  }
};
</script>

<style lang="less" scoped>
  .title{
    color: #FF0000;
    font-size: 16px;
  }
  .text{
    color: #333333;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    .w180{
      width: 180px;
      margin-left: 10px;
    }
  }

  .setting {
    border-collapse: collapse;
    margin: 0 auto;
    position: relative;
    border: 1px solid #DCDCDC !important;
    th,
    td {
      border: 1px solid #DCDCDC !important;
    }
    td {
      height: 35px;
      padding-left: 10px;
    }
    th {
      height: 40px;
      background: #F6F6F6;
      color: #333333;
      font-size: 14px;
      text-align: center;
      font-weight: bold;
      overflow: hidden;
    }
    .setting_direction {
      width: 120px;
      word-break: break-all;
      word-wrap: break-word;
    }
    .setting_subject {
      width: 300px;
      word-break: break-all;
      word-wrap: break-word;
      position: relative;
    }
    .setting_value{
      width: 220px;
      word-break: break-all;
      word-wrap: break-word;
      position: relative;
    }
    .settingSelect{
      width: 100% !important;
      /deep/ .el-input__inner {
        border: none !important;
        }
    }
    .setting_item {
      position: relative;
    }
    .setting_item:hover {
      .iconicon1-21 {
        display: block;
        cursor: pointer;
      }
      .iconicon1-18 {
        display: block;
        cursor: pointer;
      }
    }
    .iconicon1-21 {
      position: absolute;
      left: -20px;
      margin-top: 10px;
      font-size: 20px;
      color: #35c4b1;
      display: none;
    }
    .iconicon1-18 {
      position: absolute;
      margin-top: 10px;
      font-size: 20px;
      color: #ff0000;
      display: none;
    }
    }
</style>
